import { Button, Flex, Heading } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { OrganizationsList } from '../../containers/organization/OrganizationsList'
import { PageContent } from '../../containers/PageContent'
import { PageToolbar } from '../../containers/PageToolbar'
import { ShowInactiveSwitch } from '../../containers/ShowInactiveSwitch'
import { Add, Export } from '../../icons'
import { IQueryParams } from '../../model/interfaces/entities/IQueryParams'
import { ORGANIZATION_LIST_PAGE_LIMIT } from './constants'
import { useIsSupervisor, useIsSupervisorOrAdmin } from '../../model/utils/userUtils'
import { useGetOrganizationsExport } from '../../model/api/organization/useGetOrganizationExport'

export const OrganizationListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [queryParams, setRawQueryParams] = useState<IQueryParams>({
    unreadOnly: searchParams.get('unreadOnly') === '0' ? undefined : 1,
    page: searchParams.get('page') ? parseInt(searchParams.get('page')!) : 1,
    search: searchParams.get('search') ? searchParams.get('search')! : undefined,
    limit: ORGANIZATION_LIST_PAGE_LIMIT,
    organizationType: 'partner',
  })

  const setQueryParams = (params: IQueryParams) => {
    const newSearchParams: { [key in string]: string } = {
      ...(typeof params.unreadOnly === 'undefined' ? { unreadOnly: '0' } : {}),
      ...(typeof params.page !== 'undefined' && params.page !== 1
        ? { page: String(params.page) }
        : {}),
      ...(typeof params.search !== 'undefined' && params.search !== ''
        ? { search: String(params.search) }
        : {}),
    }

    setSearchParams(newSearchParams)
    setRawQueryParams(params)
  }

  const navigate = useNavigate()
  const isSupervisor = useIsSupervisor()
  const isSupervisorOrAdmin = useIsSupervisorOrAdmin()

  const downloadOrganizationExportCSV = (csvString: string) => {
    const currentDate = new Date()
    const day = currentDate.getDate().toString().padStart(2, '0')
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const year = currentDate.getFullYear().toString()
    const formattedDate = `${day}_${month}_${year}`

    const csvData = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvString], {
      type: 'text/csv;charset=utf-8',
    })
    const link = document.createElement('a')
    link.download = `organizace-export-${formattedDate}.csv`
    link.href = window.URL.createObjectURL(csvData)
    link.click()
  }

  const getOrganizationExport = useGetOrganizationsExport(
    downloadOrganizationExportCSV,
    queryParams.unreadOnly,
    queryParams.search,
  )

  return (
    <Section>
      <Container>
        <PageContent>
          <PageToolbar
            leftElements={
              <Flex sx={{ gap: 4, alignItems: 'center' }}>
                <Heading as="h1">Seznam organizací</Heading>
              </Flex>
            }
            rightElements={
              <Flex sx={{ gap: 4 }}>
                <ShowInactiveSwitch queryParams={queryParams} setQueryParams={setQueryParams} />

                {isSupervisorOrAdmin && (
                  <Button variant="outline" onClick={() => getOrganizationExport.mutate()}>
                    <Export color="secondary.500" />
                    Exportovat
                  </Button>
                )}
                <Button
                  hidden={!isSupervisor}
                  colorScheme="primary"
                  onClick={() => navigate('/organization/create')}
                >
                  <Add />
                  Vytvořit novou organizaci
                </Button>
              </Flex>
            }
          />

          <OrganizationsList queryParams={queryParams} setQueryParams={setQueryParams} />
        </PageContent>
      </Container>
    </Section>
  )
}
