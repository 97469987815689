import { Container } from '../../components/Container'
import { OfferPropertyProvider } from '../../context/OfferPropertyContext'
import { PageContent } from '../PageContent'
import { OfferPropertyGrid } from './grid/OfferPropertyGrid'
import { OffersToolbar } from './OffersToolbar'
import { IItem } from '../../model/interfaces/entities/IItem'
import { useCallback, useEffect, useState } from 'react'
import { Button, Text } from '@chakra-ui/react'
import { transformAndSortOffers } from '../../model/utils/offerUtils'
import { FormButtons } from '../FormButtons'
import { useNavigate, useParams } from 'react-router-dom'
import { OfferAdditionalInsurance } from './grid/OfferAdditionalInsurance'
import { Duplicate } from '../../icons'
import { useCustomCopyOffer } from '../../model/hooks/useCustomCopyOffer'
import useCloneClickHandler from '../../model/hooks/useCloneClickHandler'
import { useUpdateItemVisibility } from '../../model/api/offers/useUpdateItemVisibility'

export const OfferPropertyCompare = ({
  item,
  items,
  itemsWithDiscrepancy,
  viewOnly,
  offerId,
}: {
  onCompareClick?: Function
  item?: IItem
  items?: IItem[]
  itemsWithDiscrepancy?: IItem[]
  viewOnly?: boolean
  offerId?: number
}) => {
  const [selectedOnly, setSelectedOnly] = useState(true)
  const [selectingItemsIDs, setSelectingItemsIDs] = useState<number[]>([])
  const [selectingItemsInitialized, setSelectingItemsInitialized] = useState<number[]>([])
  const [sortMode, setSortMode] = useState<string>('')
  const itemsLength = items?.length || 0
  const navigate = useNavigate()
  const { id } = useParams<'id'>()
  const updateItemVisibility = useUpdateItemVisibility(id);

  const { mutateCopyOffer, isCopyingOffer } = useCustomCopyOffer(id)
  const handleCloneClick = useCloneClickHandler(mutateCopyOffer)

  const onResetFilters = () => {
    if (items) {
      setSortMode('')
      setSelectedOnly(false)
    }
  }

  const onSelectedItems = () => {
    if (selectingItemsIDs.length === ((items?.length || 0) + (itemsWithDiscrepancy?.length || 0))) {
      setSelectedOnly(false)
    }

    setSelectedOnly((val) => !val)
  }

  const handleUpdateItemVisibility = useCallback(
    async (items: any) => {
      try {
        await updateItemVisibility?.mutateAsync({
          items,
        })
      } catch (error) {
        console.log(error)
      }
    },
    [updateItemVisibility],
  )

  useEffect((): any => {
    if (JSON.stringify(selectingItemsIDs) !== JSON.stringify(selectingItemsInitialized)) {
      let _items = [...(items ?? []), ...(itemsWithDiscrepancy ?? [])].map((item) => {
        return {
          itemId: item.id,
          isVisible: selectingItemsIDs.includes(item.id)
        }
      })

      setSelectingItemsInitialized(selectingItemsIDs);

      handleUpdateItemVisibility(_items);
    }
  }, [selectingItemsIDs, items, itemsWithDiscrepancy])

  useEffect(() => {
    if (items || itemsWithDiscrepancy) {
      const initialSelectingItemsIDs: number[] = [...(items ?? []), ...(itemsWithDiscrepancy ?? [])]
        .filter((item) => item.isVisible)
        .map((item) => item.id)
      setSelectingItemsIDs(initialSelectingItemsIDs)
      setSelectingItemsInitialized(initialSelectingItemsIDs);
    }
  }, [items, itemsLength, itemsWithDiscrepancy])

  const transformedItems = items
    ? transformAndSortOffers(items, selectedOnly, selectingItemsIDs, sortMode)
    : []

  const transformedItemsWithDiscrepancy = itemsWithDiscrepancy
    ? transformAndSortOffers(itemsWithDiscrepancy, selectedOnly, selectingItemsIDs, sortMode)
    : []

  return (
    <OfferPropertyProvider>
      <PageContent>
        <Container>
          <OffersToolbar
            onResetFilters={onResetFilters}
            selectedItems={selectingItemsIDs}
            onSelectedItems={onSelectedItems}
            setSortMode={setSortMode}
            handleUpdateItemVisibility={handleUpdateItemVisibility}
            sortMode={sortMode}
            offerId={offerId}
          />
        </Container>

        {transformedItems.length === 0 && transformedItemsWithDiscrepancy.length === 0 ? (
          <Text align="center">Žádné nabídky</Text>
        ) : (
          <>
            <OfferPropertyGrid
              item={item}
              items={transformedItems}
              itemsWithDiscrepancy={transformedItemsWithDiscrepancy}
              selectingItemsIDs={selectingItemsIDs}
              setSelectingItemsIDs={setSelectingItemsIDs}
              viewOnly={viewOnly}
              offerId={offerId}
            />
          </>
        )}
        <Container>
          {!viewOnly && <OfferAdditionalInsurance id={id} />}
          {
            <FormButtons
              leftButtons={
                <Button
                  isLoading={isCopyingOffer}
                  variant="outline"
                  size="sm"
                  type="button"
                  onMouseDown={handleCloneClick}
                >
                  <Duplicate color="secondary.500" />
                  Klonovat
                </Button>
              }
            />
          }
          {viewOnly && (
            <FormButtons
              rightButtons={
                <Button colorScheme="primary" onClick={() => navigate(`/sme/wizard/view/${id}/4`)}>
                  Pokračovat
                </Button>
              }
            />
          )}
        </Container>
      </PageContent>
    </OfferPropertyProvider>
  )
}
