import {
  Box,
  Button,
  ButtonGroup, FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement, Menu, MenuButton, MenuItem, MenuList,
  Modal, ModalBody, ModalContent, ModalFooter,
  ModalHeader, ModalOverlay, Radio, RadioGroup,
  Select, Spinner, Stack,
  Text, Tooltip,
} from '@chakra-ui/react'
import { Calendar, ColumnTriple, Group, Save, SelectArrowDown, Settings, ToggleRightFilled } from '../../icons'
import Flatpickr from 'react-flatpickr'
import { Czech } from 'flatpickr/dist/l10n/cs'
import React, { useEffect, useRef, useState } from 'react'
import { useOfferOrContractData } from '../../model/hooks/useOfferOrContractData'
import { DefaultErrorCallback } from '../../model/api/DefaultErrorCallback'
import moment from 'moment'
import { ExtraInsuranceSme } from '../ExtraInsuranceSme'
import { Loading } from '../../components/Loading'
import { usePostStep3InsuranceChange } from '../../model/api/insuranceForm/usePostStep3InsuranceChange'
import { FormLabel } from '../../components/FormLabel'
import { FormControl } from '../../components/FormControl'
import { IOffersItem } from '../../model/interfaces/entities/IOffers'

const FilterOrderWrapper = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>{children}</Box>
}

const Buttons = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignContent: 'start', gap: 2 }}>{children}</Box>
  )
}

const Order = ({ setSortMode, sortMode }: { setSortMode?: Function; sortMode?: string }) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <Text sx={{ fontWeight: 'medium' }}>Řazení</Text>

      <Select
        defaultValue=""
        value={sortMode ? sortMode : ''}
        size="sm"
        onChange={(e) => setSortMode && setSortMode(e.target.value)}
      >
        <option value="" disabled>
          Dle pojistného
        </option>
        <option value="desc">Sestupně dle ceny</option>
        <option value="asc">Vzestupně dle ceny</option>
      </Select>
    </Box>
  )
}

export const OffersToolbar = ({
  showParticipation = true,
  showCompareButton = false,
  onCompareClick,
  showListButton,
  onSettingsClick,
  onSelectedItems,
  selectedItems,
  onResetFilters,
  setSortMode,
  sortMode,
  handleUpdateItemVisibility,
  offerId = undefined,
}: {
  showParticipation?: boolean
  showCompareButton?: boolean
  showListButton?: boolean
  onCompareClick?: Function
  onSettingsClick?: Function
  onSelectedItems?: (event: any) => void
  selectedItems?: number[]
  onResetFilters?: (event: any) => void
  setSortMode?: Function
  sortMode?: string
  handleUpdateItemVisibility?: (items: {itemId: number, isVisible: boolean}[]) => void
  offerId?: number
}) => {

  const [values, setValues] = useState<IOffersItem|undefined>(undefined);
  const [calendarSelected, setCalendarSelected] = useState(false);
  const [isPropertiesModalOpened, setIsPropertiesModalOpened] = useState<boolean>(false)
  const [modalValues, setModalValues] = useState<IOffersItem|undefined>(undefined)
  const [isChanged, setIsChanged] = useState(false);

  const _offerId = offerId === undefined ? undefined : offerId.toString();

  const [data] = useOfferOrContractData(
    _offerId,
    (data) => {
      setValues(data.data)
      setModalValues(data.data)
    },
    DefaultErrorCallback,
    false,
  )

  const { mutate, isLoading } = usePostStep3InsuranceChange(
    (data) => {
      //console.log('success', data)
    },
    (data) => {
      //console.log('error', data)
    }, _offerId, data)

  const newOptions = () => {
    return {
      //...options,
      dateFormat: 'd.m.Y',
      locale: Czech,
      allowInput: true,
      minDate: moment().add(1, 'day').set({hour: 0, minute: 0, second: 0}).toDate()
    };
  }

  const setCalendarDate = (date: Date) => {
    if (values) {
      setValues({
        ...values,
        insurance: {
          ...values?.insurance,
          dateStart: moment(date).format('YYYY-MM-DD'),
        }
      });
    }
  }

  useEffect(() => {
    if (values !== undefined && isChanged) {

      mutate({
        additionalInsurance: {
          assistance: values.additionalInsurance.assistance,
          flood: values.additionalInsurance.flood,
        },
        insurance: {
          type: values.insurance.type,
          range: values.insurance.range,
          dateStart: values.insurance.dateStart,
          paymentFrequency: values.insurance.paymentFrequency,
          paymentMethod: values.insurance.paymentMethod,
          companies: values.insurance.companies,
        }
      })

      setIsChanged(false);
    }
  }, [values])

  const fp = useRef(null);

  if (data === undefined || values === undefined) {
    return (
      <Loading />
    );
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, justifyContent: 'space-between' }}>
      <FilterOrderWrapper>
        <Order setSortMode={setSortMode} sortMode={sortMode} />
      </FilterOrderWrapper>

      {modalValues && (
        <Modal size="4xl" isOpen={isPropertiesModalOpened} onClose={() => setIsPropertiesModalOpened(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Nastavení parametrů pro všechny nabídky</ModalHeader>
            <ModalBody>
              <FormControl
                id="insurance.paymentFrequency"
                isInvalid={ false
                 /* touched.insurance?.paymentFrequency && !!errors.insurance?.paymentFrequency*/
                }
              >
                <FormLabel htmlFor="paymentFrequency">Frekvence plateb</FormLabel>

                <RadioGroup
                  name="insurance.paymentFrequency"
                  value={modalValues.insurance.paymentFrequency}
                  onChange={(value) => {
                    setModalValues({
                      ...modalValues,
                      insurance: {
                        ...modalValues?.insurance,
                        paymentFrequency: value
                      }
                    })
                    setIsChanged(true)
                  }}
                >
                  <Stack direction="column" spacing={4}>
                    <Radio value="year">
                      Roční
                    </Radio>
                    <Radio value="half_year">
                      Pololetní
                    </Radio>
                    <Radio value="quarter">
                      Čtvrtletní
                    </Radio>
                  </Stack>
                </RadioGroup>
                {/*{touched.insurance?.paymentFrequency && (
                  <FormErrorMessage>{errors.insurance?.paymentFrequency}</FormErrorMessage>
                )}*/}
              </FormControl>
              <FormControl>
                <div style={{margin: '2rem 1rem 1rem 0rem'}}>
                  <ExtraInsuranceSme
                    handleChange={() => {

                    }}
                    setFieldValue={(field, value) => {
                      setModalValues({
                        ...modalValues,
                        additionalInsurance: {
                          ...modalValues.additionalInsurance,
                          [field]: value
                        }
                      })
                    }}
                    values={{ ...modalValues.additionalInsurance }}
                    touched={() => {}}
                    errors={() => {}}
                  />
                </div>

              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="primary" onClick={() => {
                  console.log({
                    ...values,
                    insurance: {
                      ...values?.insurance,
                      paymentFrequency: modalValues?.insurance.paymentFrequency
                    },
                    additionalInsurance: modalValues?.additionalInsurance
                  });
                  setValues({
                    ...values,
                    insurance: {
                      ...values?.insurance,
                      paymentFrequency: modalValues?.insurance.paymentFrequency
                    },
                    additionalInsurance: modalValues?.additionalInsurance
                })
                setIsChanged(true)
                setIsPropertiesModalOpened(false)
              }}>
                <Save />
                Uložit a přepočítat
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <Buttons>
        <Button variant="outline" size="sm" onClick={() => {
          setIsPropertiesModalOpened(true)
        }}>
          <Settings color="secondary.500" />
          Parametry nabídky
          {isLoading && (
            <Spinner size="xs" />
          )}
        </Button>
        <ButtonGroup isAttached>
          <Tooltip
            hasArrow
            label={'Kliknutím nastavíte datum počátku na zítřejší datum'}
          >
            <Button variant="outline" size="sm" onClick={() => {
              setCalendarSelected(true)
              setCalendarDate(moment().add(1, 'day').toDate())
              setIsChanged(true)
            }}>
              Počátek pojištění
              {isLoading && (
                <Spinner size="xs" />
              )}
            </Button>
          </Tooltip>
          {calendarSelected ? (
            <Flatpickr
              ref={fp as any}
              options={newOptions()}
              value={moment(values.insurance.dateStart, 'YYYY-MM-DD').add(1, 'second').toDate()}
              render={({ defaultValue, value, ...props }, ref) => {
                return (
                  <Tooltip
                    hasArrow
                    label={'Kliknutím nastavíte datum počátku pomocí výběru v kalendáři'}
                  >
                    <Button variant="outline" size="sm" onClick={onSelectedItems}>
                      <Calendar color="secondary.500" />
                      <Input style={{padding: '0 5px', height: 20, border: 0}} defaultValue={defaultValue} ref={ref} htmlSize={5} />
                    </Button>
                  </Tooltip>
                )
              }}
              onChange={([date]) => {
                setCalendarDate(date)
                setIsChanged(true)
              }}
            />
          ) : (
            <Tooltip
              hasArrow
              label={'Kliknutím nastavíte datum počátku pomocí výběru v kalendáři'}
            >
              <Button variant="outline" size="sm" onClick={() => {
                setCalendarSelected(true)
                setTimeout(() => {
                  // @ts-ignore
                  fp?.current.flatpickr.open()
                }, 100);
              }}>
                <Calendar color="secondary.500" />
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>

        <ButtonGroup isAttached>
          <Button variant="outline" size="sm" onClick={onSelectedItems}>
            <ToggleRightFilled color="secondary.500" />
            Pouze vybrané
          </Button>
          <Menu>
             <MenuButton as={Button} variant="outline" size="sm">
               <SelectArrowDown boxSize={'var(--ok-sizes-3)'} />
             </MenuButton>
            <MenuList>
              <MenuItem onClick={() => {
                handleUpdateItemVisibility && handleUpdateItemVisibility([...values.items, ...(values?.itemsWithDiscrepancy ?? [])].map(_i => ({
                  itemId: _i.id,
                  isVisible: false
                })))
              }}>
                <ToggleRightFilled style={{transform: 'rotate(180deg)'}} color={'var(--ok-colors-gray-500)'} marginEnd={2} />
                Označit všechny nabídky jako neaktivní
              </MenuItem>
              <MenuItem onClick={() => {
                handleUpdateItemVisibility && handleUpdateItemVisibility([...values.items, ...(values?.itemsWithDiscrepancy ?? [])].map(_i => ({
                  itemId: _i.id,
                  isVisible: true
                })))
              }}>
                <ToggleRightFilled color="secondary.500" marginEnd={2} />
                Označit všechny nabídky jako aktivní
              </MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>


        {showCompareButton && (
          <Button
            colorScheme="primary"
            size="sm"
            onClick={() => onCompareClick && onCompareClick()}
          >
            <ColumnTriple color="white" />
            Porovnat
          </Button>
        )}

        {showListButton && (
          <Button
            colorScheme="primary"
            size="sm"
            onClick={() => onCompareClick && onCompareClick()}
          >
            <ColumnTriple color="white" />
            Zobrazit nabídky
          </Button>
        )}
      </Buttons>
    </Box>
  )
}
